.header {
  position: fixed;
  width: calc(100% - 40px);
  top: 0;
  left: 0;
  z-index: 50;
  margin: 0 20px;
}

@media (max-width: 767px) {
  .header {
    width: 100%;
    margin: 0px;
    height: 58px;
  }
}

.header__logo svg {
  height: 100%;
  width: 100%;
}

.header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 10px 10px;
  z-index: 3;
}

.header__body {
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 0;
}

@media (max-width: 767px) {
  .header__body {
    padding: 0px 0px;
    width: 100%;
    justify-content: initial;
  }
  .header__call .header__call-button {
    margin-left: 0;
    margin-right: 0;
  }
}

.header__burger {
  display: none;
  overflow: hidden;
}

.header__logo {
  position: relative;
  z-index: 3;
  display: block;
}

.header__menu {
  display: flex;
  flex: 1;
}
.header__menu.active {
  top: 0;
}

.header__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 4;
  padding-right: 20px;
}

.header__list li {
  margin-left: 36px;
}
.header__list li > a {
  min-height: 24px;
}

.header__link {
  display: block;
  min-width: max-content;
  color: var(--text-700);
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.header__link span {
  margin-right: 9px;
}

.header__right-side {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 3;
}
.header__second-logo {
  margin: 0 30px 0 10px;
}
.header__contact {
  display: flex;
  flex-direction: column;
  border-left: 2px solid var(--primary);
  padding-left: 14px;
  margin-right: 30px;
}
.header__contact {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  min-width: max-content;
}
.header-contact-title {
  text-transform: uppercase;
  color: var(--text-600);
}
.header-contact-phone {
  color: var(--primary);
}
.header__button {
  padding: 13px;
  background: var(--primary);
  border-radius: 300px;
  min-width: 50px;
}

.header__call {
  display: none;
}
.header__call-button {
  display: flex;
  align-items: center;
  padding: 9px 15px;
  max-height: 38px;
  background-color: #0d3b5b;
  border-radius: 30px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: -20px;
  white-space: nowrap;
}

.header__call-button > span {
  display: block;
  margin-left: 8px;
}
.header__mob-item {
  display: none;
}
@media (max-width: 1199.98px) {
  .header__call {
    display: block;
    position: relative;
    z-index: 1000;
  }
  .header__burger {
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 5;
    margin-right: 20px;
  }

  .header__burger:before,
  .header__burger:after {
    content: "";
    background-color: var(--text-700);
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .header__burger:before {
    top: 0;
  }

  .header__burger:after {
    bottom: 0;
  }

  .header__burger.active:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 9px;
  }

  .header__burger.active:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    bottom: 9px;
  }

  .header__burger.active span {
    -webkit-transform: translateX(-35px);
    -ms-transform: translateX(-35px);
    transform: translateX(-35px);
  }

  .header__burger span {
    background-color: var(--text-700);
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 9px;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .header__menu {
    position: fixed;
    flex-direction: column;
    top: -200%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    padding: 150px 0 0 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow: auto;
  }
  .header__list {
    flex-direction: column;
  }
  .header__list li {
    /* margin-left: 36px; */
    margin-bottom: 36px;
  }
  .header__right-side {
    display: none;
  }
  .header__mob-item {
    display: flex;
    align-items: center;
    border-top: 2px solid var(--primary);
    padding-top: 20px;
  }
  .header__mob-item .header__second-logo {
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  .header__logo {
    height: 28px;
    min-width: 68px;
    position: fixed;
    left: 15px;
    top: 15px;
    display: flex;
    align-items: center;
  }
  .header__logo img {
    height: 28px;
    max-width: 100%;
  }
  .header__burger {
    margin-right: 0px;
  }
  .header__call {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .header__menu.active {
    padding-top: 120px;
  }
  .header__burger-wrapper {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: auto;
  }
  .header__burger-wrapper:before {
    content: "";
    position: absolute;
    display: block;
    width: 58px;
    height: 100%;
    background-color: #57c3d8;
    z-index: 100;
  }
  .header__burger {
    z-index: 1000;
    width: 19px;
  }
  .header__burger:before,
  .header__burger:after,
  .header__burger > span {
    background-color: #fff;
  }
  .header__burger:before {
    top: 2px;
  }

  .header__burger:after {
    bottom: 2px;
  }
}
