.btn {
  border: 1px solid transparent;
  color: var(--defaultTextColor);
  font-size: var(--defaultFontSize);
  line-height: var(--defaultLineHeight);
  background-color: transparent;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
}
.btn-w-106 {
  width: 100%;
  max-width: 106px;
}
.btn:hover {
  opacity: 0.8;
}
.btn--outline-info {
  border: 1px solid var(--primary);
  color: var(--primary);
}
.btn--sm-rounded {
  padding: 7px;
}
.btn--info {
  background-color: var(--primary);
  color: var(--white);
}
.btn--md {
  padding: 10px 20px;
}

.btn--white {
  background-color: var(--white);
  color: var(--color-primary-700);
  border: 0;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--primary {
  background-color: #0d3b5b;
  color: white;
}
.btn--shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.btn--shadow:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
.btn--didabled {
  pointer-events: none;
  opacity: 0.5;
}
.btn--white-active {
  background-color: transparent;
  border: 1px solid var(--primary);
  box-shadow: none;
}

.radius-full {
  border-radius: 9999px;
}
