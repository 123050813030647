@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-500.woff2") format("woff2"),
    url("MuseoSans-500.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-500Italic.woff2") format("woff2"),
    url("MuseoSans-500Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-900Italic.woff2") format("woff2"),
    url("MuseoSans-900Italic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans 700";
  src:
    url("MuseoSans-700.woff2") format("woff2"),
    url("MuseoSans-700.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-900.woff2") format("woff2"),
    url("MuseoSans-900.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-300.woff2") format("woff2"),
    url("MuseoSans-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-100Italic.woff2") format("woff2"),
    url("MuseoSans-100Italic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-100.woff2") format("woff2"),
    url("MuseoSans-100.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-700Italic.woff2") format("woff2"),
    url("MuseoSans-700Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src:
    url("MuseoSans-300Italic.woff2") format("woff2"),
    url("MuseoSans-300Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
