@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
  .pt-mob-0 {
    padding-top: 0;
  }
  .pb-mob-0 {
    padding-bottom: 0;
  }
  .order-info {
    margin-left: -15px;
    margin-right: -15px;
    box-shadow: none;
    border-radius: 0;
  }
  .order-info__body {
    padding: 16px 15px 0 15px;
  }
  .mb-mob-30 {
    margin-bottom: 30px;
  }
  .review-detail-item__deliver {
    border-bottom: 0;
  }
}

@media (max-width: 767.98px) {
}

@media (max-width: 575.98px) {
  .page-action > * {
    min-width: 0;
  }
  .page-action {
    gap: 16px;
  }
  .page-action .btn--success {
    flex: auto;
  }
  .btn-actions > * {
    flex: 0 0 100%;
  }
  .btn-actions {
    gap: 8px;
  }
  .rating-list {
    font-size: 12px;
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
