.dropdown {
  max-width: max-content;
  margin: 0 auto;
}
.pt-sans-pro {
  font-family: "Source Sans Pro", sans-serif;
}
.dropdown__body {
  display: none;
  position: fixed;
  background-color: white;
  box-shadow: 0px 11px 20px rgba(0, 0, 0, 0.2);
}
.lh-24 {
  line-height: 24px;
}
.dropdown__body.is-open {
  display: block;
  white-space: nowrap;
}

.paymant-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 14px;
  border-top: 1px solid #dbe8f4;
  border-bottom: 1px solid #dbe8f4;
}
.paymant-info > * {
  margin-bottom: 14px;
}
.paymant-info__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  margin-left: -14px;
  margin-right: -14px;
  margin-top: -14px;
}
.paymant-info__list li {
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 13px;
  display: flex; /* flex */
  align-items: center;
}
.paymant-info__list li + li {
  border-left: 1px solid #dbe8f4;
}
.firs-screen-home__content .paymant-info__list li + li {
  border-left: 1px solid #bed2e4;
}
.paymant-info__cards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -10px;
}
.paymant-info__cards li {
  margin-top: 10px;
}
.paymant-info__cards li + li {
  margin-left: 10px;
}

.detail-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.detail-list li + li {
  margin-top: 20px;
}

.fag-item__button {
  display: flex;
  align-items: center;
  padding: 25px 30px;
  cursor: pointer;
}
.fag-item__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  flex: 0 0 24px;
}
.fag-item {
  transition: all 0.2s;
  overflow: hidden;
}
.fag-item__body {
  transition: all 0.2s;
}
.fag-item:hover {
  box-shadow: 0px 3px 4px 0px rgb(117 165 205 / 15%);
}
.fag-item.active .fag-item__button .fag-item__icon {
  transform: rotate(180deg);
}
.fag-item + .fag-item {
  margin-top: 10px;
}

.fag-item__answer {
  padding: 0px 30px 25px 30px;
}

.firs-screen-home {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.firs-screen-home__content {
  flex: auto;
  max-width: 808px;
  margin-right: 40px;
}

.firs-screen-home__image {
  max-width: 454px;
  flex-shrink: 0;
  max-height: 314px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
}

.filters-block {
  padding: 18px 30px;
}
.filters-block-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-left: -15px;
  margin-right: -15px;
}
.filters-block__item,
.filters-block-row > * {
  flex: 1 0 auto;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.table-pagination {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination {
  display: flex;
  align-items: center;
}

.pagination-list {
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.pagination-list li + li {
  margin-left: 4px;
}
.pagination-link {
  width: 40px;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-link:hover {
  color: #57c3d8;
}
span.pagination-link {
  pointer-events: none;
}
.pagination-link.active {
  color: #57c3d8;
  border-color: #57c3d8;
}
