.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.55);
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s linear;
  overflow-x: hidden;
}

.modal--show {
  opacity: 1;
  visibility: visible;
}

.modal--show .modal__dialog {
  transform: none;
}

.modal__dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin: auto;
  padding: 16px;
  transform: translate(0, -20px);
  transition: transform 0.3s ease-out;
}

.modal__dialog--500 {
  max-width: 540px;
}
.modal__dialog--439 {
  max-width: 470px;
}
.modal__dialog--590 {
  max-width: 590px;
}
.modal__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  position: relative;
}

.modal__body {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
}
.modal__header {
  padding: 12px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  background-color: var(--text-700);
  border-radius: 4px;
  color: var(--white);
}

.modal__footer {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
}
.btn-close {
  padding: 5px;
  margin-right: -5px;
}
