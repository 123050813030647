.footer {
  background: #0d3b5b;
}
.footer__top {
  padding: 60px 0;
  border-bottom: 1px solid #ffffff;
}

.accreditation-items {
  max-width: 920px;
  width: 100%;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}

.accreditation-items > * {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.footer-nav {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: -30px;
}
.footer-nav-item {
  flex: auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
}
.footer-nav-item:last-child {
  flex: 0 0 380px;
}
.footer__bottom {
  padding: 60px 0 40px;
}

.footer-nav-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.footer-nav-list {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #a4b9c8;
}

.footer-nav-list li + li {
  margin-top: 10px;
}

.footer-nav-list li a:hover {
  color: var(--white);
}
.footer-copyright {
  max-width: calc(1380px - 600px);
}
@media (max-width: 768px) {
  .footer-nav-item:last-child {
    flex: 0 0 100%;
  }
  .footer__top {
    padding: 30px 0;
  }
  .footer__bottom {
    padding: 30px 0 10px;
  }
}
