.input,
.textarea {
  padding: 10px;
  font-size: 14px;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--light-border);
  color: #0d3b5b;
  font-weight: 400;
  line-height: 22px;
  min-height: 44px;
}
.input.error,
.textarea.error {
  border-color: #ee3d3d;
  background: #fff3f3;
  color: #ee3d3d;
}
.input:not(.error):hover,
.textarea:not(.error):hover,
.input:not(.error):focus,
.textarea:not(.error):focus {
  border-color: var(--primary);
}
.input::placeholder,
.textarea::placeholder {
  color: #80858a;
}
input[type="date"] {
  line-height: 22px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url(../../img/icons/Calendar-Schedule.svg) no-repeat;
  width: 20px;
  height: 20px;
}
.form-group-title {
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  display: flex;
  align-items: center;
  color: var(--text-600);
  margin-bottom: 8px;
}

.search-form .input {
  border-radius: 300px;
  background-color: var(--white);
  border: 0;
  padding: 10px 50px 10px 16px;
  font-size: 12px;
  line-height: 26px;
}

.search-form__button {
  position: absolute;
  right: 3px;
  top: 3px;
}
.choices:hover .choices__inner {
  border-color: var(--primary);
}
.choices__inner {
  border: 1px solid var(--light-border);
  background: var(--white);
  min-height: 44px;
  padding: 9px 10px 3.75px;
  border-radius: 0px;
  color: var(--text-700);
}
.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 9px;
}
.choices__inner .choices__placeholder {
  opacity: 1;
}
.choices__list--single {
  padding: 0px 30px 0 0;
}

.choices[data-type*="select-one"]::after {
  border: 0;
  width: 24px;
  height: 24px;
  background-image: url(../../img/icons/arrow-down-black.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -12px;
}

.choices[data-type*="select-one"].is-open::after {
  transform: rotate(180deg);
  margin-top: -12px;
}

.choices__list--dropdown .choices__item--selectable,
.choices__list[aria-expanded] .choices__item--selectable {
  padding-right: 10px;
}

.choices__list--dropdown .choices__item--selectable::after,
.choices__list[aria-expanded] .choices__item--selectable::after {
  display: none;
}

.select {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-right: 30px;
  background-image: url(../../img/icons/arrow-down-black.svg);
  background-repeat: no-repeat;
  background-position: center right;
}
.input-clear-btn {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.input-clear-btn:hover {
  opacity: 0.7;
}
