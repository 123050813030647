.table {
  width: 100%;
  border-collapse: collapse;
}
.table td {
  font-size: 16px;
  line-height: 26px;
  padding: 9px 10px;
  border-bottom: 1px solid #dbe8f4;
}
.table th {
  color: rgba(13, 59, 91, 0.5);
  background: #eaf5fe;
  font-size: 12px;
  padding: 14px 10px;
  border-top: 1px solid #dbe8f4;
  border-bottom: 1px solid #dbe8f4;
}
.table th:first-child,
.table td:first-child {
  padding-left: 30px;
}
.table th:last-child,
.table td:last-child {
  padding-right: 30px;
}
.table-default {
  text-align: left;
  color: #0d3b5b;
  line-height: 14px;
}

.table-default tbody tr:nth-child(even) td {
  background: rgba(234, 245, 254, 0.3);
}
