.mb-12 {
  margin-bottom: 12px;
}
.section-40 {
  padding: 40px 0;
}
.custom-radio {
  display: flex;
  cursor: pointer;
}
.custom-radio__body {
  border-radius: 4px;
  border: 1px solid var(--Border, #dbe8f4);
  background: #fff;
  color: #0d3b5b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 11px 12px 11px 10px;
  transition: 0.2s;
}
.custom-radio__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.fake-icon {
  display: block;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  border: 1px solid #dbe8f4;
  border-radius: 50%;
  position: relative;
}

.custom-radio input:checked + .custom-radio__body .fake-icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #58c3d8;
  border-radius: 50%;
}

.custom-radio input:checked + .custom-radio__body,
.custom-radio:hover .custom-radio__body {
  border-color: #58c3d8;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.custom-radio input:checked + .custom-radio__body .fake-icon {
  border-color: #58c3d8;
}

.btn--outline-secondary {
  border: 1px solid var(--Border, #dbe8f4);
  background: #fff;
  color: #0d3b5b;
}

.btn--success {
  border-radius: 4px;
  background: var(--Button, #09983f);
  color: white;
}

.min-w-240 {
  min-width: 240px;
}
.min-w-120 {
  min-width: 120px;
}
.lh-22 {
  line-height: 22px !important;
}
.lh-20 {
  line-height: 20px !important;
}
.mt-32 {
  margin-top: 32px;
}
.gutters-16 {
  --bs-gutter-y: 16px;
  --bs-gutter-x: 16px;
}
.fw-700 {
  font-weight: 700;
}
.gutters-24 {
  --bs-gutter-y: 24px;
  --bs-gutter-x: 24px;
}
.order-info {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.order-info__head {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 8px;
  background-color: #09983f;
  color: white;
  padding: 10px 20px;
  line-height: normal;
}
.mb-4px {
  margin-bottom: 4px;
}
.order-info__body {
  padding: 16px 20px 20px;
}
.card-20 {
  padding: 20px;
}
.card {
  border-radius: 4px;
}

.card-primary {
  background: #eaf5fe;
  color: #0d3b5b;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.card-dash {
  border: 1px dashed #dbe8f4;
}

.card-body {
  padding: 16px;
}
.color-primary-light {
  color: #49718c;
}
.mb-16 {
  margin-bottom: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-14 {
  font-size: 14px;
  line-height: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mr-8 {
  margin-right: 8px;
}
.mt-24 {
  margin-top: 24px;
}
.bg-styles-top-center {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.btn-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.btn-actions > * {
  flex: 0 0 50%;
}
.g-24 {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 24px;
}
.btn--primary-light {
  border-radius: 4px;
  background-color: #dbe8f4;
}
.review-detail-item {
  display: flex;
  gap: 10px;
}
.review-detail-item__name {
}
.review-detail-item__deliver {
  border-bottom: 1px dashed #dbe8f4;
  flex: auto;
}
.review-detail-item__value {
  color: var(--text-700);
}

.hr {
  border: 0;
  border-bottom: 1px solid #dbe8f4;
}

.mt-30 {
  margin-top: 30px;
}

.spoller__head {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: space-between;
  cursor: pointer;
}
.spoller__body {
  transition: all 0.3s ease;
  overflow: hidden;
}
.spoller.active .spoller__head.active .spoller__head-arrow {
  transform: rotate(180deg);
}
.spoller__head-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.order-info__body-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.spoller__head-style-blue {
  padding: 8px 10px 8px 12px;
  border-radius: 4px;
  background: #eaf5fe;
  color: #0d3b5b;
  align-items: center;
}

.spoller__line {
  flex: auto;
  height: 1px;
  background-color: #dbe8f4;
}
