@media (max-width: 1440px) {
  .header .container {
    max-width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .header__body:before {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
  .section-dell-2 {
    margin-top: 0;
  }
  .heading-4-sm {
    font-size: 20px;
  }
  .row-granty {
    flex-wrap: wrap;
  }
  .section-dell {
    margin-top: 0;
  }
  .card-row {
    flex-direction: column;
  }
  .card-row--item {
    min-width: auto !important;
    max-width: 100% !important;
  }
  .max-w-350 {
    max-width: 100%;
  }
}

@media (max-width: 575.98px) {
  .section-60 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .dbl-btns--mob {
    flex-direction: column;
  }
  .dbl-btns--mob > .btn {
    margin-right: 0 !important;
  }
  .input-find {
    white-space: wrap;
  }
  .list-country {
    flex-wrap: wrap;
  }
  .w-full-mob {
    width: 100%;
  }
  .input-find {
    flex-direction: column;
    align-items: flex-start;
  }
  .find-date,
  .link-counrty {
    line-height: 1.4em;
  }
  .order-1-mob {
    order: 1;
  }
  .section-40:not(.pt-mob-0, .pb-mob-0) {
    padding: 20px 0 !important;
  }
  .items-40 > *:not(:last-child) {
    margin-bottom: 30px;
  }
  .fag > .heading-3 {
    margin-bottom: 10px;
  }
  .firs-screen-home--container {
    padding: 12px;
    margin-bottom: 16px;
  }
  .heading-2 {
    font-size: 20px;
    line-height: 28px;
  }
  .title-16-mob {
    font-size: 16px;
    line-height: 28px;
  }
  .form-group--bottom-mob-none {
    padding-bottom: 0;
  }
  .card-sm-mob {
    border: none !important;
    padding: 0 !important;
  }
  .container .card-mob-container {
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
  }
  .card-24 {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  .rating-blocks {
    flex-direction: column;
  }
  .rating-blocks > .rating-block--item:not(:last-child) {
    margin-bottom: 12px;
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
