.scroll_to_top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  background: #f8f8f8 url(../img/to_top.svg) no-repeat center;
  background-size: 70%;
  transition: all 0.15s ease-in-out;
  z-index: 10;
}

.scroll_to_top.active {
  opacity: 0.6;
  visibility: visible;
}

.scroll_to_top.active:hover {
  opacity: 1;
}

/* 09.10.2023 */
.section-dell-2 {
  margin-bottom: -100px;
  margin-top: -75px;
  z-index: 1;
  position: relative;
}
.section-dell-2 + section {
  padding-top: 90px !important;
}
.leading-24 {
  line-height: 24px !important;
}
.card-24.lightcard {
  padding: 24px;
}
.wrapper.bg-styles-top-center {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto;
}
.choices__list--dropdown .choices__item:not(:last-child),
.choices__list[aria-expanded] .choices__item:not(:last-child) {
  border-bottom: 1px solid #dbe8f4 !important;
}
.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  color: var(--text-700) !important;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #dbe8f4 !important;
}
.mt-10 {
  margin-top: 10px;
}

.font-700 {
  font-weight: 700;
}
.def-text-1 {
  font-size: 14px;
  line-height: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-15 {
  margin-bottom: 15px;
}
.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pt-30 {
  padding-top: 30px;
}
.color-text--600 {
  color: var(--text-600);
}
.color-text--700 {
  color: var(--text-700);
}
.color-text--success {
  color: #09983f;
}
.color-white {
  color: var(--white);
}

.w-full {
  width: 100%;
}
.tdu {
  text-decoration: underline;
}
.r-4 {
  border-radius: 4px;
}
.text-1 {
  font-size: 14px;
}
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.max-w-350 {
  max-width: 350px;
}
.max-w-275 {
  max-width: 275px;
}
.mb--16 {
  margin-bottom: -16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.overflow-hidden {
  overflow: hidden;
}
.fag-item--sm .fag-item__button {
  padding: 22px;
}
.max-h-40 {
  max-height: 40px;
}
.fag-item--sm .fag-item__answer {
  padding-left: 22px;
  padding-right: 22px;
}
.ui-datepicker {
  display: none;
}
.btn-success {
  background-color: #09983f;
  color: var(--white);
  border: 1px solid transparent;
}
.btn-success:hover {
  border-color: #09983f;
  color: #09983f;
  background-color: var(--white);
}
.mx-12 {
  margin: 0 12px;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.pl-40 {
  padding-left: 40px;
}
.gutters-x-16 {
  --bs-gutter-x: 16px;
}
/*.input{color:var(--text-700);}*/
.datapicker {
  font-size: 14px !important;
  line-height: normal !important;
}
.input::placeholder,
.textarea::placeholder {
  font-weight: 400 !important;
  color: var(--text-600);
}
.--placeholder-custom::placeholder {
  color: #80858a !important;
}
.container-900 {
  max-width: 904px;
}
.container-900.container {
  max-width: calc(904px + 30px);
}
.container-670 {
  max-width: 670px;
}
.container-670.container {
  max-width: calc(670px + 30px);
}
.heading-1 {
  font-size: var(--h1Size);
  line-height: 42px;
}
.heading-2 {
  font-size: var(--h2Size);
  line-height: 42px;
}
.heading-3 {
  font-size: var(--h3Size);
  line-height: 42px;
}
.heading-4 {
  font-size: var(--h4Size);
  line-height: 28px;
}
.heading-5 {
  font-size: var(--h5Size);
  line-height: 24px;
}
.section-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.section-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.firs-screen-home--container {
  padding: 24px 30px;
  background-color: var(--text-700);
  flex-direction: column;
  display: flex;
  color: var(--white);
  align-items: flex-start;
}
.firs-screen-home--container h1,
.firs-screen-home--container h2,
.firs-screen-home--container h3,
.firs-screen-home--container h4,
.firs-screen-home--container h5,
.firs-screen-home--container h6 {
  color: var(--white);
}
.firs-screen-home--container .firs-screen-home__content {
  margin-right: 0;
  max-width: 100%;
}

.mw-188 {
  max-width: 188px;
}
.ico {
  display: flex;
  align-items: center;
}
.ico-20 {
  min-width: 20px;
  max-width: 20px;
  height: 20px;
}
.ico-50 {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
}
.ico-15 {
  min-width: 18px;
  max-width: 18px;
  height: 15px;
}

.gutters-y-15 {
  --bs-gutter-y: 15px;
}

.ico-12 {
  min-width: 12px;
  max-width: 12px;
  height: 12px;
}
.wrapp-input {
  position: relative;
  display: flex;
}
.wrapp-input > .ico {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

[type="search"]::-webkit-search-cancel-button {
  position: relative;
  right: -5px;
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background: url("../img/img-09-10-23/btn-close-input.svg") no-repeat center
    center / 12px;
  opacity: 1 !important;
  display: block !important;
  overflow: visible !important;
  visibility: visible !important;
}

.filters-block-row.filters-block-row--12 > * {
  margin-left: 12px;
  margin-right: 12px;
}
.filters-block-row.filters-block-row {
  margin-left: -12px;
  margin-right: -12px;
}

.list-locations {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: -4px;
}
.list-locations > li {
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
}
.form-group--bottom {
  padding-bottom: 28px;
  position: relative;
}
.form-group--bottom .list-locations {
  position: absolute;
  height: 20px;
  overflow: hidden;
}

.items {
  display: flex;
  flex-direction: column;
}
.items-40 > *:not(:last-child) {
  margin-bottom: 40px;
}
.items-24 > *:not(:last-child) {
  margin-bottom: 24px;
}
.items-10 > *:not(:last-child) {
  margin-bottom: 10px;
}
.items-5 > *:not(:last-child) {
  margin-bottom: 5px;
}
.lightcard {
  background-color: var(--bg-light);
}
.card {
  display: flex;
  flex-direction: column;
}
.card-24 {
  /*padding: 24px;*/
  padding: 23px 15px 20px 15px;
}
.card-24 .card-header + .card-body {
  margin-top: 20px;
}
.card-border {
  border: 1px dashed var(--light-border);
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
.card-header > * {
  margin-bottom: 10px;
}
.card-header > *:not(:last-child) {
  margin-right: 10px;
}

.dbl-btns {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -12px;
}
.dbl-btns > * {
  margin-bottom: 12px;
}
.dbl-btns--16 > *:not(:last-child) {
  margin-right: 16px;
}

.paymant-info--column {
  flex-direction: column;
  align-items: flex-start !important;
}
.paymant-info__list.paymant-info--column li + li {
  border-left: none;
}
.paymant-info__list.paymant-info--column {
  margin-left: 0;
  margin-right: 0;
}
.paymant-info__list.paymant-info--column li {
  padding-left: 0;
  padding-right: 0;
}
.paymant-info.paymant-info--column > ul:last-child {
  margin-bottom: 0;
}

.emit-rating {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -2px;
  margin-right: -2px;
  align-items: center;
}
.emit-rating--item {
  padding-left: 2px;
  padding-right: 2px;
}

.emit-rating--1 {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -1px;
  margin-right: -1px;
  align-items: center;
}
.emit-rating--1 .emit-rating--item {
  padding-left: 1px;
  padding-right: 1px;
}
.rating-blocks {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
}
.rating-blocks > .rating-block--item {
  padding-left: 8px;
  padding-right: 8px;
  flex: 1;
}
.rating-block {
  flex: 1;
  border-radius: 4px;
  border: 1px solid var(--light-border);
  background: var(--white);
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  min-height: 116.5px;
}
.rating-block > *:not(:last-child) {
  margin-bottom: 11px;
}
.rating-list {
  display: flex;
  font-size: 14px;
  font-style: normal;
  margin-left: -5px;
  margin-right: -5px;
  line-height: 1em;
  margin-bottom: -6px;
}
.rating-list li {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 6px;
}
.rating-list li + li {
  border-left: 1px solid var(--text-600);
}
.h-full {
  height: 100%;
}
.max-w-max {
  max-width: max-content;
}
.row-granty {
  flex-wrap: nowrap;
}

.section-dell {
  margin-bottom: -80px;
  z-index: 1;
  position: relative;
  margin-top: -80px;
}
.section-dell + section {
  padding-top: 90px !important;
}
.box-shadow2 {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.input-find {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
}
.input-find > *:not(:last-child) {
  margin-right: 8px;
}
.list-country {
  display: flex;
  align-items: center;
}
.list-country li {
  display: flex;
}
.list-country li:not(:last-child) {
  margin-right: 5px;
}
.list-country li:not(:last-child)::after {
  content: ",";
}
.find-date {
  font-weight: 300;
  color: var(--text-600);
}

.wrapp-input .btn {
  position: absolute;
  right: 0;
  height: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.min-w-187 {
  min-width: 187px;
}
.footer-add {
  background-color: var(--bg-light);
  padding: 20px 20px;
  display: flex;
  margin-bottom: -12px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-add > * {
  margin-bottom: 12px;
}
.footer-add > *:not(:last-child) {
  margin-right: 12px;
}

.pill-message {
  border-radius: 4px;
  background: #eaf5fe;
  padding: 10px;
  color: #4a718c;
  font-size: 14px;
  line-height: 22px;
}
.pill-message-2 {
  border-radius: 4px;
  border: 1px dashed #dbe8f4;
  background: #f8f8f8;
  padding: 24px 20px;
  font-size: 14px;
  line-height: 22px;
}
.result-search-body {
  margin-bottom: 24px;
}
.result-search,
.result-search-body {
  display: flex;
  flex-direction: column;
}
.result-search .result-search-footer {
  margin-bottom: 30px;
}
.result-search .result-search-body + .result-search-footer {
  margin-top: 6px;
}
.result-search-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.result-search-title > * {
  margin-bottom: 11px;
}
.result-search-title > *:not(:last-child) {
  margin-right: 12px;
}

.card-row {
  display: flex;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: var(--white);
}
.card-row--item {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: 4px;
  flex: auto;
}
.laction-info {
  display: flex;
}
.card-row--item .laction-info {
  margin-left: -20px;
  margin-top: -20px;
  margin-bottom: 16px;
}
.laction-info .location-text:first-child {
  border-radius: 4px 0px 0px 0px;
}
.laction-info .location-text.miles:first-child {
  border-radius: 4px 0px 4px 0px;
}
.laction-info .location-text:last-child {
  border-radius: 0px 0px 4px 0px;
}
.location-text {
  background-color: var(--text-700);
  color: var(--white);
  padding: 1px 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.location-text.miles {
  background-color: #58c3d8;
}
.select-shadow-wrapp .choices__inner {
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.flex-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -2px;
  flex-wrap: wrap;
}
.flex-option > * {
  margin-bottom: 2px;
}
.flex-option > *:not(:last-child) {
  margin-right: 10px;
}
.text-default {
  font-size: var(--defaultFontSize);
  color: var(--defaultTextColor);
  line-height: normal;
}

.result-search-list {
  display: flex;
  flex-direction: column;
}
.result-search-list > li:not(:last-child) {
  margin-bottom: 16px;
}
.card-row--item.lightcard {
  min-width: 297px;
  max-width: 297px;
}

.pagination-no-responsive {
  display: flex;
  align-items: center;
}

.pagination-no-responsive .pagination-list {
  display: flex;
  align-items: center;
  padding: 0 30px !important;
}
.pagination-no-responsive .pagination-link {
  width: 40px !important;
  height: 40px !important;
}

.form .form-body + .form-footer {
  margin-top: 30px;
}
.form,
.form-footer,
.form-body {
  display: flex;
  flex-direction: column;
}
.--disabled {
  pointer-events: none;
  background-color: #cccccc !important;
  color: #fff !important;
}
/* 09.10.2023 */

.lds-spinner {
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--primary);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spoller__head.spoller__head--style-2 {
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
}

.spoller__head.spoller__head--style-2 .spoller__line {
  display: none;
}
