:root {
  --defaultTextColor: #49718c;
  /* tipografy */
  --fotnFamily: "Museo Sans";
  --defaultFontSize: 16px;
  --defaultLineHeight: 24px;
  --h1Size: 48px;
  --h2Size: 36px;
  --h3Size: 24px;
  --h4Size: 20px;
  --h5Size: 16px;
  /* tipografy */

  /* colors */
  --white: #fff;
  --text-700: #0d3b5b;
  --text-600: #49718c;
  --primary: #57c3d8;
  --light-border: #dbe8f4;
  --bg-light: #eaf5fe;
  --required-color: #ee3d3d;
  --link-color: #a4b9c8;
  /* colors */

  /* shadows */
  --shadow-1: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* shadows */

  --defaultRaduis: 6px;
  --radius-10: 10px;
}

body {
  font-size: 100%;
  font-weight: normal;
  font-family: var(--fotnFamily);
  font-weight: 400;
  font-size: var(--defaultFontSize);
  line-height: var(--defaultLineHeight);
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: var(--defaultTextColor);
}

body.lock {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: bold;
  font-family: var(--fotnFamily);
  line-height: 133.333%;
  color: var(--text-700);
}

h1 {
  font-size: var(--h1Size);
}

h2 {
  font-size: var(--h2Size);
}

h3 {
  font-size: var(--h3Size);
}

h4 {
  font-size: var(--h4Size);
}

h5 {
  font-size: var(--h5Size);
}
input,
button,
textarea {
  font-family: var(--fotnFamily);
}
.radius-5 {
  border-radius: var(--defaultRaduis);
}
.radius-10 {
  border-radius: 10px;
}
.rounded {
  border-radius: 50%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.header-fixed {
  padding-top: 96px;
}
.content {
  flex: auto;
}
.container {
  max-width: 1390px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  height: 100%;
}

.ttu {
  text-transform: uppercase;
}

.color-danger {
  color: var(--required-color) !important;
}
.color-primary {
  color: var(--primary) !important;
}
.color-primary-700 {
  color: var(--text-700) !important;
}
.color-default {
  color: var(--defaultTextColor) !important;
}
.color-white {
  color: var(--white) !important;
}
.bg-primary-100 {
  background-color: var(--bg-light);
}
.bg-white {
  background-color: var(--white);
}
.bg-styles-top-right {
  background-repeat: no-repeat;
  background-position: top right;
  background-repeat: no-repeat;
}
.bg-styles-bottom-right {
  background-repeat: no-repeat;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.relative {
  position: relative !important;
}

.resize-none {
  resize: none;
}

.p-14 {
  padding: 14px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-30 {
  margin-right: 30px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-40 {
  margin-bottom: 60px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.-mt-70 {
  margin-top: -70px;
}
.-top-70 {
  top: -70px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-140 {
  padding-bottom: 140px !important;
}
.flex-auto {
  flex: auto;
}

.w-100 {
  width: 100%;
}

.fw-600 {
  font-weight: 600;
}

.fs-12 {
  font-size: 12px;
}

.mr-40 {
  margin-right: 40px;
}

.mw-350 {
  max-width: 350px;
}
.mw-600 {
  max-width: 600px;
}
.mw-106 {
  max-width: 106px;
}
.mw-180 {
  max-width: 180px;
}
.section {
  padding: 70px 0;
}

.container-lg {
  max-width: 930px;
}

.gutters-20 {
  --bs-gutter-x: 20px;
  --bs-gutter-y: 24px;
}
.gutters-10 {
  --bs-gutter-x: 10px;
  --bs-gutter-y: 20px;
}
.gutters-80 {
  --bs-gutter-x: 80px;
}
.flex-80 {
  flex: 0 0 80px;
}
.border-bottom {
  border-bottom: 1px solid #dbe8f4;
}

.text-center {
  text-align: center;
}

.border-none {
  border: 0 !important;
}

.box-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.table-wrapper {
  overflow: auto;
}

.white-spase-nowrap {
  white-space: nowrap;
}
