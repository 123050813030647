.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.btn.disabled {
  pointer-events: none;
  cursor: no-drop;
}
.btn.disabled.btn--info {
  background: #cccccc;
}
.react-datepicker__input-container input,
.react-datepicker__input-container input::placeholder {
  color: var(--text-700) !important;
}
.iframe-3ds {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  max-width: 700px;
  min-height: 400px;
  /* height: 340px; */
  padding: 15px;
  background: #fff;
  border: 0.5px solid #e1e1e1;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
}
.table-default tbody tr td .table-default tbody tr td {
  background: #fff;
}
.input_with_suffix {
  position: relative;
}
.input_suffix {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 11px;
  color: var(--text-700);
  pointer-events: none;
}
.input_suffix span {
  color: var(--text-600);
}
.input_with_suffix.on-focus .input_suffix {
  display: none;
}
.input_with_suffix input {
  transition: 0s;
}
.input_with_suffix:not(.on-focus) input {
  color: transparent;
}
.input_with_suffix.colored input {
  color: var(--text-700);
}
.lds-spinner.primary div:after {
  background: var(--primary);
}
.fag-item__answer p a {
  text-decoration: underline;
}
.header {
  max-width: 1400px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 15px;
  margin: 0;
}
.header:before {
  box-shadow: 7px 7px 20px 0 rgb(7 34 52 / 10%);
  border-radius: 0px 0px 5px 5px;
}
.header__list li {
  margin-left: 40px;
}

.header nav ul li {
  position: relative;
}

nav .nav-search-form {
  max-width: 412px;
  width: 100%;
  position: absolute;
  top: 105px;
  right: 0;
  transform: translateY(-20px);
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

nav .nav-search-form.is-open {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

nav .nav-search-form input {
  display: block;
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  border: solid 2px #dbe8f4;
  margin-bottom: 10px;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
  color: #49718c;
}

nav .nav-search-form input.search {
  border: 0;
  border-radius: 5px;
  box-shadow: 7px 7px 20px 0 rgb(7 34 52 / 10%);
  height: 50px;
  font-weight: 500;
}
.nav-big-submenu-title {
  font-size: 14px;
  padding: 10px 0;
}

.header nav ul .sub-menu {
  display: none;
}

.header .header__link .open_sub_menu_arrow {
  display: flex;
  align-items: center;
}
.hero-section-title-loader {
  background: #dceaf5;
  border-radius: 10px;
  min-height: 58px;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
  animation: blink normal 1.5s infinite ease-in-out;
}
.error-badge {
  border-radius: 360px;
  border: 1px solid #ee3d3d;
  background: #ee3d3d;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 5px 12px;
  margin-top: 12px;
  min-height: 32px;
}
.error-badge svg {
  margin-right: 10px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}
.order-details-description p + p {
  margin-top: 16px;
}
.mb-m-24 {
  display: block;
  margin-bottom: -24px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .header nav ul .sub-menu {
    position: relative;
    padding: 40px;
    background-color: #fff;
    display: none;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  .header nav > ul > li {
    margin-left: 40px;
  }
  .header nav ul li:hover ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    background-color: white;
    min-height: 300px;
    padding: 20px 40px;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
  }
  .header nav ul li:hover ul.regular-nav {
    position: absolute;
    width: auto;
    margin-left: 0;
    min-height: unset !important;
    padding: 0;
    left: 0;
    right: 0;
    /* width: 200%; */
    width: 233px;
    top: 96px;
  }
  .header nav ul li:hover ul.regular-nav li {
    width: 100%;
    padding: 0;
    margin-right: 0;
    margin-left: 0px;
    line-height: 0;
    margin-bottom: 0;
  }
  .header nav ul li:hover ul li a {
    color: #0d3b5b;
    width: 100%;
    float: left;
    text-align: left;
    margin-left: 0px;
    line-height: 19px;
    border-bottom: 2px solid #ebeef1;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.6px;
  }
  .header nav ul li:hover ul.regular-nav li a {
    padding: 16px 15px;
    border-bottom: none;
    font-size: 14px;
  }
  .header nav ul .sub-menu .mobile-menu-back {
    display: none;
  }
  .header nav .header__list > li {
    padding: 39px 0;
    line-height: 60px;
  }
  .header__body {
    padding: 0;
  }
  .header nav ul li:hover ul.regular-nav li a:hover {
    background-color: #ebeef1;
  }
  .header nav ul li a:hover {
    opacity: 0.5;
  }
  .header nav ul li.menu-item-object-page:hover ul li ul {
    width: 100%;
    margin-left: 0px;
    top: 0px;
    left: 0px;
    position: relative;
    padding: 0px;
    min-height: 0px;
    box-shadow: none;
  }
  .header nav ul li.menu-item-object-page:hover ul li ul {
    background: transparent;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .header nav ul li.menu-item-object-page:hover ul {
    /* width: 1200px; */
    width: 800px;
    left: 100%;
    margin-left: -300px;
    top: 96px;
  }
  .header nav ul li.menu-item-object-page:hover ul li {
    width: 30%;
    font-size: 20px;
    float: left;
    margin-left: 5%;
    border-bottom: 0px;
  }
  .header nav ul li.menu-item-object-page:hover ul li:first-of-type {
    /* width: 65%; */
    width: 100%;
    margin: 0px;
  }
  .header nav ul li.menu-item-object-page:hover ul li ul li a h5 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .header
    nav
    ul
    li.menu-item-object-page:not(.menu-item-object-page-two):hover
    ul
    li
    ul
    li:hover
    a
    h5 {
    text-decoration: underline;
  }
  .header nav ul li.menu-item-object-page:hover ul li ul li {
    height: auto;
    width: 48%;
    margin: 3px 2% 3px 0;
  }
  .header nav ul li.menu-item-object-page:hover ul li ul li:first-of-type {
    width: 48%;
    margin: 3px 2% 0 0;
  }
  .header nav ul li.menu-item-object-page .mh.menu-course-category {
    display: flex;
    padding: 8px;
  }
  .header nav ul li.menu-item-object-page .mh.menu-course-category a {
    border: 0;
  }
  .header nav ul li.menu-item-object-page:hover ul li ul li a img {
    max-width: 60px;
    width: 100%;
  }
  .header nav .dropdown .dropdown-menu.dropdown-menu-right,
  .header nav .dropdown .multiple-columns-container {
    position: absolute;
    display: none;
    padding: 0;
    background-color: #fff;
    margin-top: -1px;
    right: auto;
    white-space: nowrap;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    left: 100%;
  }
  .header nav .multiple-columns-container {
    width: 670px;
  }
  .header nav .multiple-columns-container {
    display: none;
    z-index: 99999;
  }
  .header nav .dropdown-toggle.haschild h5:after {
    content: "\00BB";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    margin-left: 10px;
    color: #57c3d8;
    font-size: 18px;
  }
  .header nav ul li.menu-item-object-page:hover ul li ul li a {
    border: none;
    width: 100%;
    float: left;
    padding: 5px;
  }
  .header nav .dropdown {
    position: relative;
    padding: 0;
    margin: 0;
  }
  .header
    nav
    ul
    li.menu-item-object-page.menu-item-object-page-two
    .mh.menu-course-category {
    padding: 5px 0;
    margin-bottom: 7px;
  }
  .header nav .dropdown:hover .dropdown-menu,
  .header nav .dropdown:active .dropdown-menu,
  .header nav .dropdown:focus .dropdown-menu {
    display: block;
    z-index: 2;
  }
  .header nav .dropdown:hover .dropdown-menu li {
    display: flex;
    align-items: center;
  }
  .header nav .dropdown:hover .dropdown-menu li a {
    display: flex;
    font-size: 13px;
  }
  .header nav ul li.menu-item-object-page:hover .dropdown ul li:first-of-type,
  .header nav .dropdown:hover .dropdown-menu li {
    width: 100%;
    margin: 0;
    /* padding: 10px 10px; */
  }
  /* .header nav ul li.menu-item-object-page:hover .dropdown ul li:first-of-type a,
	.header nav .dropdown:hover .dropdown-menu li a {
		font-weight: 400;
	} */
  .header
    nav
    ul
    li.menu-item-object-page:hover
    .dropdown
    ul
    li:first-of-type
    img,
  .header nav .dropdown:hover .dropdown-menu li img {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 14px;
    width: 100%;
  }
  .header .dropdown {
    max-width: initial;
  }
  .header .dropdown-menu.multiple-columns-container .multiple-columns {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100vh - 350px);
    overflow-y: auto;
  }
  .header
    .dropdown-menu.multiple-columns-container
    .multiple-columns
    .col-items {
    display: flex;
    align-items: center;
    width: 33.33%;
    position: relative;
  }
  .header
    .dropdown-menu.multiple-columns-container
    .multiple-columns
    .col-items
    img {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 14px;
    width: 100%;
  }
  .header nav ul li.menu-item-object-page:hover .dropdown ul li:first-of-type a,
  .header nav .dropdown:hover .dropdown-menu li a,
  .header
    .dropdown-menu.multiple-columns-container
    .multiple-columns
    .col-items
    a {
    font-size: 14px;
    font-weight: 400;
    padding: 19px 13px;
    padding-left: 48px;
    display: block;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header nav ul li.menu-item-object-page:hover .dropdown ul li:first-of-type a,
  .header nav .dropdown:hover .dropdown-menu li a {
    line-height: 14px;
  }
  .header
    nav
    ul
    li.menu-item-object-page:hover
    .dropdown
    ul
    li:first-of-type
    a:hover,
  .header nav .dropdown:hover .dropdown-menu li a:hover,
  .header
    .dropdown-menu.multiple-columns-container
    .multiple-columns
    .col-items
    a:hover {
    background-color: #ebeef1;
    opacity: 1;
  }
  .header {
    z-index: 9999;
  }
  body:has(.modal.modal--show) .header {
    z-index: 1;
  }
  .header_hover_bg {
    background: #000;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  .desktop-height-78 {
    height: 78.5625px;
  }
  .desktop-height-36 {
    height: 36.3333px;
  }
  .desktop-height-35 {
    height: 35px;
  }
  .header-contact-phone {
    font-size: 14px;
  }
  .header__right-side {
  }
}

@media (min-width: 1700px) {
  .header nav .multiple-columns-container {
    width: 970px;
  }
  .header
    .dropdown-menu.multiple-columns-container
    .multiple-columns
    .col-items {
    width: 25%;
  }
}

@media (max-width: 1340px) {
  .header__right-side {
    display: none;
  }
}

@media (max-width: 1200px) {
  .header {
    padding-right: 0;
  }
  .header .header__menu {
    height: 100vh;
    background-color: #eaf5fe;
    padding-top: 118px;
    top: 0;
    transform: translateX(20%);
    opacity: 0;
    visibility: hidden;
    width: calc(100% + 40px);
    left: -20px;
  }
  .header__menu.active {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
  /* .header .header__menu .header__mob-item {
		display: none;
	} */
  .header .header__menu .header__mob-item {
    flex-direction: column;
    align-items: flex-start;
    border: 0;
    padding: 28px;
  }
  .header .header__list > li {
    margin-bottom: 15px;
    margin-left: 28px;
    max-width: calc(90% - 50px);
  }
  .header .header__menu .header__mob-item .search-form__button {
    display: none;
  }
  nav .mobile-menu-search input.search {
    border: 0;
    border-radius: 45px;
    box-shadow: 7px 7px 20px 0 rgb(7 34 52 / 10%);
    height: 50px;
    font-weight: 500;
    font-size: 10px;
    padding: 0 25px;
    display: block;
    width: 100%;
  }
  nav .mobile-menu-search .search-btn {
    margin: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
    background-color: #57c3d8;
  }
  nav .mobile-menu-search {
    width: 100%;
    margin-top: 50px;
    position: relative;
  }
  .header .header__link {
    width: 100%;
  }
  .header .header__link .open_sub_menu_arrow {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .header nav ul .sub-menu {
    background-color: #eaf5fe;
    padding: 40px 28px;
    visibility: hidden;
    opacity: 0;
    transform: translateX(40px);
    transition: all ease 0.3s;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    left: 0;
    z-index: 2;
    padding-top: 116px;
  }
  .header nav ul .sub-menu.open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    overflow-y: auto;
    display: block;
  }
  .col-xs-12.col-sm-3.p-left--zero.w3_bg {
    display: none;
  }
  .header nav ul .sub-menu.open li {
    margin-left: 0;
    margin-bottom: 0;
  }
  .header nav ul .sub-menu.open li ul li {
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  .header nav ul .sub-menu.open li ul li a h5,
  .header nav ul .sub-menu.open li ul li a {
    font-weight: 600;
    font-size: 14px;
  }
  .header nav ul .sub-menu.open li ul li a:hover {
    opacity: 0.5;
  }
  .nav-big-submenu-title {
    display: none;
  }
  nav ul .sub-menu .mobile-menu-back::before {
    content: "";
    background-image: url(https://www.skillstg.co.uk/app/themes/skills-training-group/assets/css/../images/navigation-dropdown.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 10px;
    display: inline-block;
    transform: rotate(90deg);
    margin-right: 5px;
  }
  nav ul .sub-menu .mobile-menu-back {
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
    color: #0d3b5b;
  }
  .header nav ul .sub-menu.open li ul li.mobile-menu-back,
  .header nav ul .menu-item-object-custom .sub-menu.open .mobile-menu-back {
    margin-bottom: 30px;
  }
  .header nav ul .menu-item-object-custom .sub-menu.open li a {
    font-weight: 600;
    font-size: 14px;
    color: #0d3b5b;
  }
  .header nav ul .menu-item-object-custom .sub-menu.open li {
    text-transform: uppercase;
    margin-bottom: 14px;
  }
  .dropdown-menu {
    display: none;
  }
  .header nav .dropdown-toggle.haschild h5:after {
    content: "\00BB";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    margin-left: 10px;
    color: #57c3d8;
    font-size: 18px;
  }
  .header nav .dropdown {
    margin: 0;
    max-width: 100%;
  }
  .dropdown-menu.open {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }
  .dropdown-menu.open li,
  .header
    nav
    ul
    .sub-menu.open
    .dropdown-menu.open
    .multiple-columns
    .col-items {
    position: relative;
  }
  .dropdown-menu.open li img,
  .header
    nav
    ul
    .sub-menu.open
    .dropdown-menu.open
    .multiple-columns
    .col-items
    img {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 15px;
    width: 100%;
  }
  .header nav ul .sub-menu.open li ul.dropdown-menu.open li a,
  .header nav ul .sub-menu.open .dropdown-menu.open .multiple-columns a {
    font-size: 14px;
    font-weight: 400;
    padding: 15px 13px;
    padding-left: 35px;
    display: block;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0d3b5b;
  }
  .header nav ul .sub-menu.open li ul.dropdown-menu.open li a:hover,
  .header nav ul .sub-menu.open .dropdown-menu.open .multiple-columns a:hover {
    background-color: #ebeef1;
  }
  .header nav ul .sub-menu.open li ul.dropdown-menu.open li {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .header .header__menu {
    left: 0;
    width: 100%;
    padding-top: 98px;
  }
  .header nav ul .sub-menu {
    padding-top: 102px;
  }
}
@media (max-width: 575.98px) {
  .modal__body.py-40.px-60 {
    padding: 40px 20px;
  }
  .modal__body.py-40.px-60 .dbl-btns--mob {
    flex-direction: column-reverse;
  }
}
