@media (max-width: 1199.98px) {
  .header-fixed {
    padding-top: 58px;
  }
}

@media (max-width: 991.98px) {
  .mb-lg-30 {
    margin-bottom: 30px;
  }
  .fag-item__button {
    padding: 15px 20px;
  }
  .fag-item__answer {
    padding: 0px 20px 15px 20px;
  }
  .firs-screen-home {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .firs-screen-home__image {
    margin-top: 30px;
  }
  .firs-screen-home__content {
    margin-right: 0;
  }
  .filters-block__item,
  .filters-block-row > * {
    flex: auto;
    max-width: none;
  }
  .table-pagination {
    flex-direction: column;
  }
  .show-count {
    margin-bottom: 20px;
  }
  .pagination-list {
    padding: 0 10px;
  }
  .pagination-link {
    width: 30px;
    height: 30px;
  }
  .table-pagination,
  .filters-block {
    padding: 20px 10px;
  }
}

@media (max-width: 767.98px) {
  body {
    --h1Size: 34px;
    --h2Size: 26px;
    --h3Size: 20px;
    --h4Size: 18px;
  }
  .section {
    padding: 30px 0;
  }
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
  .top-md-0 {
    top: 0;
  }
}

@media (max-width: 575.98px) {
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
